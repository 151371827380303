import React, { Component } from 'react';
import { firestore } from '../firebase-config'; 
import { ids_firebase, getEmailPorId } from '../content-site'; 

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

class Rodape extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailsRevendas: [],
      enderecosRevendas: []
    };
  }

  componentDidMount() {
    const emailsRevendas = [];
    const enderecosRevendas = [];

 
    ids_firebase.forEach(id => {
  
      const email = getEmailPorId(id);
      if (email) {
        emailsRevendas.push(email);
      }


      firestore.collection("revenda").doc(id)
        .get()
        .then(doc => {
          if (doc.exists) {
            const revendaData = doc.data();
            enderecosRevendas.push({
              endereco: revendaData.peendereco_fiscal,
              numero: revendaData.peendereco_fiscal_numero,
              bairro: revendaData.pebairro_fiscal,
              cidade: revendaData.pecidade,
              uf: revendaData.peuf,
              mapa_link: revendaData.mapa_link
            });
          }
        })
        .catch(error => {
          console.error("Erro ao buscar informações da revenda: ", error);
        });
    });


    this.setState({
      emailsRevendas: emailsRevendas,
      enderecosRevendas: enderecosRevendas
    });
  }

  render() {
    const { emailsRevendas, enderecosRevendas } = this.state;

    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo.png"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href={this.props.revenda.facebook_link} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-facebook-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href={this.props.revenda.instagram_link} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-instagram" target="_blank"></i>
                    </a>
                  </li>
                  <li>
                    <a href={this.props.revenda.mapa_link} className="wow slideInLeft" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>


          <div className="row" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h6 className="text-sobrenos">Sobre nós</h6>
                <p className="text-sobrenos-info">
                Na Tops Motors, você encontrará uma equipe comprometida em oferecer uma experiência excepcional. Com uma ampla seleção de carros novos e usados de alta qualidade, nós nos destacamos por nosso atendimento dedicado.
                </p>
              </div>
            </div>


            <div className="col-lg-7 col-md-12">
              <h6 className="text-sobrenos">Endereços</h6>
              <div className="usefull-link">
                <div className="row">

                  {enderecosRevendas.map((endereco, index) => (
                    <div key={index} className="col-md-12 footer_info">
                      <ul className="text-sobrenos-info">
                        <li>
                          <a href={endereco.mapa_link} target="_blank" rel="noopener noreferrer">
                            {endereco.endereco} - {endereco.numero} - {endereco.bairro} - {endereco.cidade} - {endereco.uf}
                          </a>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            {emailsRevendas.length > 0 && (
              <div className="col-lg-7 col-md-12">
                <h6 className="text-sobrenos">Contatos</h6>
                <ul className="text-sobrenos-info">
                  {emailsRevendas.map((email, index) => (
                    <li key={index}>
                      <i className="fa fa-envelope-o" style={{ marginRight: "10px" }}></i>
                      <a href={"mailto:" + email}>{email}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>


        <div className="copyright">
          <div className="container">
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p style={{ textAlign: "center" }}>
                    © Copyright 2024 Canever Veículos | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="HKe2fZ02jqcU0ixfKIxz"> Intermédio Veículos Marketing Digital</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Rodape;
