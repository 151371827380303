// alterar arquivo: public/index.html
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "8cGlcWDnEIIuUUgQxnmJ";  // TOPS DO IMIGRANTES


export const ids_firebase = [
    "8cGlcWDnEIIuUUgQxnmJ", // Canever Imigrantes 0
    "b5XYti4HOtwjDiQEFlvU" // Canever Central 1
];

    export const href_revenda = [
        "veiculo_tops_imigrantes",
        "veiculo_tops_central"

    ];
    
    // EMAIL POR ID 
    export const emailsPorId = {
        "8cGlcWDnEIIuUUgQxnmJ": "topsmotorscristal@gmail.com", //EMAIL TOPS IMIGRANTES
        "b5XYti4HOtwjDiQEFlvU": "topsmotorscentral@gmail.com", //EMAIL TOPS CENTRAL

      };
      
      export function getEmailPorId(idRevenda) {
        return emailsPorId[idRevenda] || "E-mail não disponível";
      }
      